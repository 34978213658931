import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { graphql } from "gatsby";
import LightGallery from "lightgallery/react";

import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";

import "lightgallery/scss/lightgallery.scss";
import "lightgallery/scss/lg-zoom.scss";

import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";
import lgHash from "lightgallery/plugins/hash";
import lgFullscreen from "lightgallery/plugins/fullscreen";
import Layout from "../components/common/Layout";
import Anchor from "../components/elements/Anchor";
import Seo from "../components/meta/Seo";

const VillaPage = ({ data }) => {
  if (!data) return null;
  const { page } = data;
  const { services } = page.data;

  return (
    <>
      <Seo page={page} />
      <Layout>
        <div className="about container pt-3 pt-md-0">
          <div className="villa-images">
            <div className="image-wrapper">
              <div className="left-image villa-hero-image">
                <GatsbyImage
                  image={page?.data?.image?.gatsbyImageData}
                  className="d-none d-xl-block"
                  alt={page?.data?.image?.alt || "About image"}
                />
              </div>
              <div className="right-image villa-hero-image">
                <GatsbyImage
                  image={page?.data?.second_image?.gatsbyImageData}
                  className="d-none d-xl-block"
                  alt={page?.data?.second_image?.alt || "About image"}
                />
              </div>
              <div className="top-image villa-hero-image">
                <GatsbyImage
                  image={page?.data?.fourth_image?.gatsbyImageData}
                  className="d-none d-xl-block"
                  alt={page?.data?.fourth_image?.alt || "About image"}
                />
              </div>
              <div className="bottom-image villa-hero-image">
                <GatsbyImage
                  image={page?.data?.third_image?.gatsbyImageData}
                  className="d-none d-xl-block"
                  alt={page?.data?.third_image?.alt || "About image"}
                />
              </div>
              <GatsbyImage
                image={page?.data?.image_mobile?.gatsbyImageData}
                className="d-block d-xl-none m-auto h-100 mobile-img"
                alt={page?.data?.image?.alt || "About image"}
              />
            </div>
          </div>

          <div className="row justify-content-center justify-content-md-start mt-5 my-md-3">
            {services.map((serviceItem) => {
              return (
                <div className="d-flex flex-column max me-sm-3">
                  <GatsbyImage
                    image={serviceItem?.icon?.gatsbyImageData}
                    alt={serviceItem?.icon?.alt || "service"}
                    className="pool-icons"
                  />
                  <p className="mt-auto">{serviceItem?.text}</p>
                </div>
              );
            })}
          </div>

          <div className="row justify-content-center position-relative">
            <div className="col-12">
              <div className="about-text mt-5 mt-xxl-3">
                {page?.data?.about_text?.richText?.map((item, i) => {
                  // eslint-disable-next-line react/no-array-index-key
                  return <p key={i}>{item.text}</p>;
                })}
              </div>
            </div>
          </div>

          <div className="row justify-content-center my-5 d-block d-sm-none">
            <div className="col-12">
              <div className="text-center">
                <Anchor
                  href={page?.data?.button_link_about?.url || "#"}
                  className="services-btn text-center"
                >
                  {page?.data?.button}
                </Anchor>
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-12">
              <div className="App" id="lightgallery">
                <LightGallery
                  speed={500}
                  plugins={[lgZoom, lgFullscreen, lgHash, lgThumbnail]}
                >
                  {page?.data?.about_slider?.map((slide) => {
                    return (
                      <a
                        href={slide?.image?.url}
                        className="text-center"
                        key={slide?.image?.url}
                      >
                        <img
                          src={slide?.image?.url}
                          alt={slide?.image?.alt || "Gallery image"}
                          className="gallery-img"
                        />
                      </a>
                    );
                  })}
                </LightGallery>
              </div>
            </div>
          </div>

          <div className="row justify-content-center my-5 d-none d-sm-block">
            <div className="col-12">
              <div className="text-center">
                <Anchor
                  href={page.data.button_link_about.url || "#"}
                  className="services-btn text-center"
                >
                  {page.data.button}
                </Anchor>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export const query = graphql`
  query aboutQuery($lang: String) {
    settings: prismicSettings(lang: { eq: $lang }) {
      ...prismicSettingsFragment
    }
    page: prismicVillaPage {
      alternate_languages {
        uid
        type
        lang
      }
      lang
      type
      url
      data {
        button
        button_link_about {
          url
        }
        services {
          icon {
            gatsbyImageData(placeholder: BLURRED)
            alt
          }
          text
        }
        about_text {
          richText
        }
        about_slider {
          image {
            url
            alt
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        meta_description
        meta_image {
          alt
          dimensions {
            height
            width
          }
          url
        }
        meta_title
        image {
          alt

          gatsbyImageData(placeholder: BLURRED)
        }
        second_image {
          alt
          gatsbyImageData(placeholder: BLURRED)
        }
        third_image {
          alt
          gatsbyImageData(placeholder: BLURRED)
        }
        fourth_image {
          alt
          gatsbyImageData(placeholder: BLURRED)
        }
        image_mobile {
          alt
          gatsbyImageData
        }
      }
    }
  }
`;

export default VillaPage;
